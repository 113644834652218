import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import LoaderWithBackground from './components/loader/LoaderWithBackground';

const PublicRoute = React.lazy(() => import('./utils/routes/PublicRoute'));
const PrivateRoute = React.lazy(() => import('./utils/routes/PrivateRoute'));
const AuthPage = React.lazy(() => import('./pages/AuthPage/AuthPage'));
const MainPage = React.lazy(() => import('./pages/MainPage/MainPage'));
const DashboardPage = React.lazy(() => import('./pages/Dashboard/DashboardPage'));
const ImportPage = React.lazy(() => import('./pages/ImportPage/ImportPage'));
const ExportPage = React.lazy(() => import('./pages/ExportPage/ExportPage'));
const PersonalAccount = React.lazy(() => import('./pages/PersonalAccountPage/PersonalAccount'));
const CreateProductPage = React.lazy(() => import('./pages/CreateProductPage/CreateProductPage'));
const ProductSettings = React.lazy(() => import('./pages/ProductSettingsPage/ProductSettings'));

function App() {
  return (
    <Router>
      <Suspense fallback={<LoaderWithBackground />}>
        <Switch>
          <PublicRoute exact path="/" component={AuthPage}/>
          <PrivateRoute exact path="/main" component={MainPage}/>
          <PrivateRoute exact path="/personalAccount" component={PersonalAccount}/>
          <PrivateRoute exact path="/productSettings" component={ProductSettings}/>
          <PrivateRoute exact path="/createProduct" component={CreateProductPage}/>
          <PrivateRoute exact path="/import" component={ImportPage}/>
          <PrivateRoute exact path="/export" component={ExportPage}/>
          <PrivateRoute exact path="/dashboard" component={DashboardPage}/>
          <Redirect from="*" to="/dashboard"/>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
