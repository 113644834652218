import styled, {css} from 'styled-components';

import {ReactComponent as addIcon} from './add-icon.svg';
import {ReactComponent as exitIcon} from './exit-icon.svg';
import {ReactComponent as logoIcon} from './logo-icon.svg';
import {ReactComponent as userIcon} from './user-icon.svg';
import {ReactComponent as notificationIcon} from './notification-icon.svg';
import {ReactComponent as notificationIconEmpty} from './notification-icon-empty.svg';
import {ReactComponent as sliderIcon} from './slider-icon.svg';
import {ReactComponent as settingsIcon} from './settings-icon.svg';
import {ReactComponent as importIcon} from './import-icon.svg';
import {ReactComponent as exportIcon} from './export-icon.svg';
import {ReactComponent as sectionIcon} from './section-icon.svg';
import {ReactComponent as lexemeIcon} from './lexeme-icon.svg';
import {ReactComponent as filterIcon} from './filter-icon.svg';
import {ReactComponent as searchIcon} from './search-icon.svg';
import {ReactComponent as flagUkActiveIcon} from './flag-uk-active-icon.svg';
import {ReactComponent as flagRuActiveIcon} from './flag-ru-active-icon.svg';
import {ReactComponent as flagJoActiveIcon} from './flag-jo-active-icon.svg';
import {ReactComponent as addFolderIcon} from './add-folder-icon.svg';
import {ReactComponent as addLexemeIcon} from './add-lexeme-icon.svg';
import {ReactComponent as editIcon} from './edit-icon.svg';
import {ReactComponent as flagUkEmojiIcon} from './flag-emoji-uk-icon.svg';
import {ReactComponent as flagRuEmojiIcon} from './flag-emoji-ru-icon.svg';
import {ReactComponent as flagJoEmojiIcon} from './flag-emoji-jo-icon.svg';
import {ReactComponent as closeRoundIcon} from './close-round-icon.svg';
import {ReactComponent as arrowOpenIcon} from './arrow-open-icon.svg';
import {ReactComponent as arrowLeftIcon} from './arrow-left-icon.svg';
import {ReactComponent as orDividerIcon} from './or-divider-icon.svg';
import {ReactComponent as qrCodeIcon} from './qr-code-icon.svg';
import {ReactComponent as commentIcon} from './comment-icon.svg';
import {ReactComponent as copyIcon} from './copy-icon.svg';
import {ReactComponent as deleteIcon} from './delete-icon.svg';
import {ReactComponent as okIcon} from './ok-icon.svg';
import {ReactComponent as closeIcon} from './close-icon.svg';
import {ReactComponent as sendIcon} from './send-icon.svg';
import {ReactComponent as smallExportIcon} from './small-export-icon.svg';
import {ReactComponent as smallImportIcon} from './small-import-icon.svg';
import {ReactComponent as changesIcon} from './changes-icon.svg';
import {ReactComponent as dotsIcon} from './dots-icon.svg';
import {ReactComponent as figmaIcon} from './figma-icon.svg';
import {ReactComponent as moveIcon} from './move-icon.svg';
import {ReactComponent as dotIcon} from './dot-icon.svg';
import {ReactComponent as middleImportIcon} from './middle-import-icon.svg';
import {ReactComponent as middleExportIcon} from './middle-export-icon.svg';
import {ReactComponent as starIcon} from './star-icon.svg';
import {ReactComponent as middleCommentIcon} from './middle-comment-icon.svg';
import {ReactComponent as bigAddIcon} from './big-add-icon.svg';
import {ReactComponent as starTransparentIcon} from './star-transparent-icon.svg';
import {ReactComponent as addCircleIcon} from './add-circle.svg';
import {ReactComponent as openFolderIcon} from './open-folder-icon.svg';
import {ReactComponent as cancelStatusIcon} from './cancel-lexeme-status-icon.svg';
import {ReactComponent as sendOnApproveIcon} from './send-on-approve-icon.svg';
import {ReactComponent as successIcon} from './success-icon.svg';
import {ReactComponent as infoIcon} from './info-icon.svg';
import {ReactComponent as lockIcon} from './lock-icon.svg';
import {ReactComponent as eyeIcon} from './eye-icon.svg';
import {ReactComponent as eyeOffIcon} from './eye-off-icon.svg';
import {ReactComponent as warningIcon} from './warning-icon.svg';
import {ReactComponent as inProgressIcon} from './in-progress-icon.svg';
import {ReactComponent as questionCircleIcon} from './question-circle.svg';
import {ReactComponent as exportSettingsIcon} from './export-settings-icon.svg';
import {ReactComponent as successApproveIcon} from './success-approve-icon.svg';
import {ReactComponent as linkIcon} from './link-icon.svg';

export const AddIconStyled = styled(addIcon)`
  padding: 20px;
  cursor: pointer;
`;

export const AddCircleIconStyled = styled(addCircleIcon)`
  cursor: pointer;
`;

export const BigAddIconStyled = styled(bigAddIcon)`
  cursor: pointer;
`;

export const ExitIconStyled = styled(exitIcon)`
  cursor: pointer;

`;

export const LogoIconStyled = styled(logoIcon)`
  display: block;
  margin: 0 auto ${props => props.marginbottom};

  ${props => props.onClick ? 'cursor: pointer;' : ''}
  
  ${props => props.marginbottom === '30px' && css`
    width: 93px;
    height: 70px;
  `}

`;

// export const UserIconStyled = styled(userIcon)`
//   cursor: pointer;
//
// `

export const UserIconStyled = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;

  background: #ECEDF4;
  border-radius: 8px;
`;

export const NotificationIconStyled = styled(notificationIcon)`
  cursor: pointer;

`;

export const NotificationIconEmptyStyled = styled(notificationIconEmpty)`
  cursor: pointer;

`;

export const SliderIconStyled = styled(sliderIcon)`
  cursor: ew-resize;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0 auto -4.5px;
`;

export const SettingsIconStyled = styled(settingsIcon)`
  display: block;
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
  &:hover {
    path {
      stroke: #304DDE;
    }
  }
`;

export const ImportIconStyled = styled(importIcon)`
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ExportIconStyled = styled(exportIcon)`
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const SectionIconStyled = styled(sectionIcon)`
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const LexemeIconStyled = styled(lexemeIcon)`
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const FilterIconStyled = styled(filterIcon)`
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const SearchIconStyled = styled(searchIcon)`
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const FlagUkActiveIconStyled = styled(flagUkActiveIcon)`
  cursor: pointer;
  display: block;
  opacity: ${props => props.active ? 1 : 0.5};

  
    &:hover {
      rect {
        stroke: #5C73E5;
        stroke-width: 1px;
      }
    }
`;

export const FlagRuActiveIconStyled = styled(flagRuActiveIcon)`
  cursor: pointer;
  display: block;
  opacity: ${props => props.active ? 1 : 0.5};

    &:hover {
      rect {
        stroke: #5C73E5;
        stroke-width: 1px;
      }
    }
`;

export const FlagJoActiveIconStyled = styled(flagJoActiveIcon)`
  cursor: pointer;
  display: block;
  opacity: ${props => props.active ? 1 : 0.5};

    &:hover {
      rect {
        stroke: #5C73E5;
        stroke-width: 1px;
      }
    }

`;

export const EditIconStyled = styled(editIcon)`
  cursor: pointer;
  display: block;
  margin: ${props => props.margin ? props.margin : 'auto 0'};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const AddFolderIconStyled = styled(addFolderIcon)`
  cursor: pointer;
  display: block;
  margin: auto 0 ;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const AddLexemeIconStyled = styled(addLexemeIcon)`
  cursor: pointer;
  display: block;
  margin: auto 0;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const FlagUkEmojiStyled = styled(flagUkEmojiIcon)`

`;

export const FlagRuEmojiStyled = styled(flagRuEmojiIcon)`

`;

export const FlagJoEmojiStyled = styled(flagJoEmojiIcon)`

`;

export const CloseRoundIconStyled = styled(closeRoundIcon)`
`;

export const ArrowOpenIconStyled = styled(arrowOpenIcon)`
  transform: ${props => props.show ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const ArrowLeftIconStyled = styled(arrowLeftIcon)`
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;

  cursor: pointer;
`;

export const OrDividerIconStyled = styled(orDividerIcon)`
`;

export const QrCodeIconStyled = styled(qrCodeIcon)`
`;

export const CommentIconStyled = styled(commentIcon)`
`;

export const MiddleCommentIconStyled = styled(middleCommentIcon)`
  margin: 6px;
  cursor: pointer;
  min-width: 18px;
  &:hover {
    path {
      stroke: #304DDE;
    }
  }
`;

export const ChangesIconStyled = styled(changesIcon)`
  cursor: pointer;
  flex: none;
`;

export const CopyIconStyled = styled(copyIcon)`
  cursor: pointer;
  flex: none;
`;

export const DeleteIconStyled = styled(deleteIcon)`
  cursor: pointer;
  margin: auto 0;
  min-width: 18px;
`;

export const OkIconStyled = styled(okIcon)`
  cursor: pointer;
`;

export const CloseIconStyled = styled(closeIcon)`
  cursor: pointer;
  margin: auto 0;
`;

export const SendIconStyled = styled(sendIcon)`
  cursor: pointer;
  margin: auto 0;
`;

export const SmallExportIconStyled = styled(smallExportIcon)`
  cursor: pointer;
  min-width: 18px;
`;

export const SmallImportIconStyled = styled(smallImportIcon)`
  cursor: pointer;
  min-width: 18px;
`;

export const MiddleImportIconStyled = styled(middleImportIcon)`
  margin: 6px;
  cursor: pointer;
  min-width: 18px;
  &:hover {
    path {
      stroke: #304DDE;
      //stroke-width: 5px;
    }
  }
`;

export const MiddleExportIconStyled = styled(middleExportIcon)`
  margin: 6px;
  cursor: pointer;
  min-width: 18px;
  &:hover {
    path {
      stroke: #304DDE;
      //stroke-width: 5px;
    }
  }
`;

export const DotsIconStyled = styled(dotsIcon)`
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const DotIconStyled = styled(dotIcon)`
  min-width: 6px;
  min-height: 14px;
`;

export const FigmaIconStyled = styled(figmaIcon)`
  min-width: 18px;
  cursor: pointer;
`;

export const MoveIconStyled = styled(moveIcon)`
  min-width: 18px;
`;
export const StarIcon = styled(starIcon)`
  min-width: 18px;
  cursor: pointer;
 
`;

export const StarTransparentIcon = styled(starTransparentIcon)`
  min-width: 18px;
  cursor: pointer;
`;

export const OpenFolderIcon = styled(openFolderIcon)`
  cursor: pointer;
`;

export const CancelStatusIcon = styled(cancelStatusIcon)`
  cursor: pointer;
`;

export const SendOnApproveIcon = styled(sendOnApproveIcon)`
  cursor: pointer;
`;

export const SuccessIcon = styled(successIcon)`
  cursor: pointer;
`;

export const InfoIcon = styled(infoIcon)`
  cursor: pointer;
`;

export const LockIcon = styled(lockIcon)`
  ${props => props.onClick && 'cursor: pointer;'}
`;

export const EyeIcon = styled(eyeIcon)`
  ${props => props.onClick && 'cursor: pointer;'}
`;

export const EyeOffIcon = styled(eyeOffIcon)`
  ${props => props.onClick && 'cursor: pointer;'}
`;

export const WarningIcon = styled(warningIcon)`
  cursor: pointer;
`;

export const InProgressIcon = styled(inProgressIcon)`
`;

export const QuestionCircleIcon = styled(questionCircleIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const ExportSettingsIcon = styled(exportSettingsIcon)`
  cursor: pointer;
`;

export const SuccessApproveIcon = styled(successApproveIcon)`
`;

export const LinkIcon = styled(linkIcon)`
  cursor: pointer;
`;